<template>
<div>
    <div class="content">
         <div class="banner">
            <div class="banner_img">
            <div class="banner_word_one">
              集成电路数字化学习平台
            </div>
            <div class="banner_word_two">
               <span>政、校、企协同共建  服务国家战略级产业</span> 
              </div>
              <div class="lj">
                  <img src="~@/assets/img_index/images/lj.png" alt="">
              </div>
              <div class="banner_word_three"> 
                工信部人才交流中心高校人才培养合作伙伴
              </div>
            </div>
        </div>
        <div class="lz">
                <img src="~@/assets/img_index/images/lz.png" alt="">
        </div>
        <div class="middle_word">
            <span>四大进阶、循序渐进  快速构建学生能力</span>
        </div>
        <div class="box">
        <div class="dbx"> 
            <div class="lx">
                <div class="rest">
                <div class="lx_img">
                    <img src="~@/assets/img_index/images/hkkj_icon1.png" alt="">

                </div>
                <div class="lx_word">
                    熟练平台工具
                </div>
            </div>
            </div>
        </div>
        <div class="dbx"> 
            <div class="lx">
                <div class="rest">
                <div class="lx_img">
                    <img src="~@/assets/img_index/images/hkkj_icon2.png" alt="">

                </div>
                <div class="lx_word">
                    大量案例实战
                </div>
            </div>
            </div>
        </div>
        <div class="dbx"> 
            <div class="lx">
                <div class="rest">
                <div class="lx_img">
                    <img src="~@/assets/img_index/images/hkkj_icon3.png" alt="">

                </div>
                <div class="lx_word">
                    配套企业课程 
                </div>
            </div>
            </div>
        </div>
        <div class="dbx"> 
            <div class="lx">
                <div class="rest">
                <div class="lx_img">
                    <img src="~@/assets/img_index/images/hkkj_icon4.png" alt="">

                </div>
                <div class="lx_word">
                    赛事及认证支撑
                </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sypt">
            <div class="sypt_left">
                <img src="~@/assets/img_index/images/XLAB.png" alt="">
            </div>
            <div class="sypt_right">
                <div class="sypt_right_word">
                    <span>实验平台</span>
                </div>
                <div class="sypt_right_word1">
                    <span>部署集成电路龙头企业工具与真实项目、为高校师生提供实践教学资源</span>
                </div>
                <ul class="sypt_list">
                    <li class="first">
                        <div class="list_t1">
                        <img src="~@/assets/img_index/images/sz1.png" alt="">
                    </div>
                    <div class="list_word">
                       <span> 全面提高学生技能和竞争力</span>
                    </div>
                    <div class="list_lists">
                            <div class="list_lists_left">
                                <div class="qiu">
                                    
                                </div>
                                <div class="qiu">
                                    
                                </div>
                                <div class="qiu">
                                    
                                </div>
                            </div>
                            <div class="wz">
                                <p>全"交互式"实验课件体验</p>
                                <p>完整记录学生学习过程 </p>
                                <p>建立数字化教学评价机制</p>
                            </div>
                    </div>
                    <div class="line">

                    </div>
                    </li>
                    <li>
                        <div class="list_t1">
                            <img src="~@/assets/img_index/images/sz2.png" alt="">
                        </div>
                        <div class="list_word">
                            <span>整合集成电路产业资源</span> 
                        </div>
                        <div class="list_lists">
                                <div class="list_lists_left">
                                    <div class="qiu">
                                        
                                    </div>
                                    <div class="qiu">
                                        
                                    </div>
                                    <div class="qiu">
                                        
                                    </div>
                                   

                                </div>
                                <div class="wz">
                                    <p>部署行业先进的工具平台</p>
                                    <p>来自企业一线的真实项目案例 </p>
                                    <p>国内外企业行业专家联合研发</p>
                                </div>
                        </div>
                        <div class="line">

                        </div>
                    </li>
                    <li class="last">
                        <div class="list_t1">
                            <img src="~@/assets/img_index/images/sz3.png" alt="">
                        </div>
                        <div class="list_word">
                         <span>全面提升学习体验和效果</span>   
                        </div>
                        <div class="list_lists">
                                <div class="list_lists_left ts">
                                    <div class="qiu">
                                        
                                    </div>
                                    <div class="qiu">
                                        
                                    </div>
                                   
                                    <div class="qiu ts1">
                                        
                                    </div>
                                </div>
                                <div class="wz">
                                    <p>每个学生“独占式”实验环境</p>
                                    <p> 7X24小时可用，无时间、空间限制 </p>
                                    <p> 一键启动，灵活自主</p>
                                </div>
                        </div>
                        <div class="line">

                        </div>
                    </li>
                </ul>
                <div class="down_one">

                </div>
            </div>
            
    </div>
        <footer>
        <div class="footer_img">
            <div class="footer_word_one">
                <span>认证中心</span>
            </div>
            <div class="footer_word_one1">
                <span>学、练、考一体化，培养集成电路应用型人才</span>
            </div>
            <ul class="lb">
                <li class="lb_first">
                    <div class="lb_img">
                        <img src="~@/assets/img_index/images/rz.png" alt="">
                    </div>
                    <div class="lb_word">
                        <span>权威行业认证体系</span>
                    </div>
                </li>
                <li>
                    <div class="lb_img">
                        <img src="~@/assets/img_index/images/rz1.png" alt="">
                    </div>
                    <div class="lb_word">
                        <span>学、练、考一体化</span>
                    </div>
                </li>
                <li>
                    <div class="lb_img">
                        <img src="~@/assets/img_index/images/rz2.png" alt="">
                    </div>
                    <div class="lb_word">
                        <span>学历教育与职业培训有机融合</span>
                    </div>
                </li>
                <li>
                    <div class="lb_img">
                        <img src="~@/assets/img_index/images/rz3.png" alt="">
                    </div>
                    <div class="lb_word">
                        <span>服务产业人才配套</span>
                    </div>
                </li>
            </ul>
        </div>
    </footer>

    <div class="kc">
        <span>课程中心</span>
    </div>
    <div class="kc_one">
        <span>行业前沿的集成电路专业课程，示范院校名师及行业资深专家亲授</span>
    </div>
    <div class="kc_main">
        <ul class="kc_main_left">
            <li class="li_one">
                <div>
                    <img src="~@/assets/img_index/images/kc_icon1.png" alt="">
                </div>
                <div class="li_one_word">
                    行业前沿课程
                </div>
                <div class="li_one_word1">
                    聚焦行业前沿技术，集成电路专业核心课程
                </div>
            </li>
            <li>
                <div>
                    <img src="~@/assets/img_index/images/kc_icon2.png" alt="">
                </div>
                <div class="li_one_word">
                    专业先导课
                </div>
                <div class="li_one_word1 word_ts">
                    联合集成电路示范院校名师共研的具备“金”念、“金”内涵、“金”体系的线上课程
                </div>
            </li>
            <li>
                <div>
                    <img src="~@/assets/img_index/images/kc_icon3.png" alt="">
                </div>
                <div class="li_one_word">
                    课程解决方案
                </div>
                <div class="li_one_word1 word_ts">
                    提供MOOC课程解决方案，实现教育教学质量和人才培养质量提升
                </div>
            </li>
        </ul>
        <div class="kc_main_right">
                <div class="mask">

                </div>
        </div>
    </div>
    
    </div>
</div>
    
</template>

<script>
import '@/assets/font/PingFang-SC-Regular.css'
export default {
  components: {
    
  },
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>

<style scoped>
  * {
    margin: 0;
    padding: 0;
  }
  li{
      list-style: none;
  }
.content{
    width: 100%;
    margin: 0 auto;
    min-width: 1200px;
    max-width: 1920px;
}
    .banner{
    width: 100%;
    height: 100%;
    background:#000000;
}
.banner_img{
    background: url('~@/assets/img_index/images/bj_hx.png') no-repeat center center;
    height: 740px;
    background-size: cover;
    overflow: hidden;

}
.banner_word_one{
height: 100px;
font-size: 60px;
font-family: PingFangSC-Semibold, PingFang SC, PingFang-SC-Regular;
font-weight: 600;
color: #F9F9F9;
line-height: 100px;
margin-top: 156px;
margin-left: 11%;
}
.banner_word_two{
    width: 637px;
height: 71px;
background: #231DFF;
display: flex;
align-items: center;
margin-left: 11%;

}
.banner_word_two span{
height: 42px;
font-size: 30px;
font-family: PingFangSC-Semibold, PingFang SC, PingFang-SC-Regular;
font-weight: 600;
color: #F9F9F9;
line-height: 42px;
margin-left: 3%;
}
.lj{
    width: 110px;
    height: 11px;
    margin-top: 177px;
    margin-left: 11%;
}
.banner_word_three{
height: 33px;
font-size: 24px;
font-family: PingFangSC-Regular, PingFang SC,PingFang-SC-Regular;
font-weight: 400;
color: #FFFFFF;
line-height: 33px;
letter-spacing: 2px;
margin-top: 15px;
margin-left: 11%;
}
.lz{
    width: 100%;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.middle_word{
    width: 100%;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.middle_word span{
height: 67px;
font-size: 48px;
font-family: PingFangSC-Semibold, PingFang SC,PingFang-SC-Regular;
font-weight: 600;
color: #000000;
line-height: 67px;
}
.lx{
             width: 170px;
            height: 170px;
            background: #231DFF;
}
.dbx{
    width: 180px;
    height: 180px;
    border: 1px solid #FFFFFF;
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    outline: 1px solid #D9DDE2; ;
    margin-left: 5.5%;

   
}
.rest{
    transform: rotate(-45deg);

}
.box{
    width: 100%;
    height: 413px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lx_img{
    width: 80px;
    height: 80px;
    margin-left: 40%;
    margin-top: 24%;
}
.lx_word{
    height: 33px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC,PingFang-SC-Regular;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 33px;
    letter-spacing: 1px;
    margin-left: 22%;
    margin-top: -6%;
}
.sypt{
    width: 100%;
    height: 860px;
    display: flex;
    background: #F0F2F6;
}
.sypt_left{
    height: 100%;
    max-width:290.5px ;

}
.sypt_left img{
    width: 100%;
    height: 100%;
    max-width:290.5px ;

   
}
.sypt_right{
    width: 100%;
    position: relative;
}
.sypt_right_word{
    width: 100%;
    height: 151px;
    display: flex;
    justify-content: center;


}
.sypt_right_word span{
height: 67px;
font-size: 48px;
font-family: PingFangSC-Semibold, PingFang SC,PingFang-SC-Regular;
font-weight: 600;
color: #000000;
line-height: 67px;
display: block;
margin-top: 60px;
margin-left: -10%;
}
.sypt_right_word1{
    width: 100%;
    height: 33px;
    display: flex;
    justify-content: center;
}
.sypt_right_word1 span{
height: 33px;
font-size: 21px;
font-family: PingFangSC-Regular, PingFang SC,PingFang-SC-Regular;
font-weight: 400;
color: #000000;
line-height: 33px;
opacity: 0.6;
    }

    .sypt_list{
        height: 484px;
        margin-top: 82px;
        justify-content: center;
        display: flex;
        flex: 1;
    }
    .sypt_list li{
        width: 340px;
        height: 484px;
        background: #FFFFFF;
        margin-left: 90px;
    }
    .first{
        margin-left: 0 !important;
    }
    .list_t1{
        width: 49px;
        height: 80px;
        margin-top: 51px;
        margin-left: 32px;
    }
    .list_word{
height: 90px;
font-size: 31px;
font-family: PingFangSC-Semibold, PingFang SC,PingFang-SC-Regular;
font-weight: 600;
color: #000000;
line-height: 45px;
margin-top: 18px;
margin-left: 6%;
    }
    .list_word span{
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        width: 272px;
    }
    .list_lists{
        width: 284px;
        height: 163px;
        margin-top: 34px;
        margin-left: 32px;
        margin-right: auto;
        overflow: hidden;
        display: flex;
    }
    .list_lists_left{
        width: 4px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
        margin-left: 3%;
    }
    .qiu{
        width: 4px;
        height: 4px;
        background:  #231DFF;
        overflow: hidden;
    }
    .wz{
height: 114px;
font-size: 20px;
font-family: PingFangSC-Regular, PingFang SC,PingFang-SC-Regular;
font-weight: 400;
color: #000000;
line-height: 38px;
margin-top: 16px;
margin-left: 9px;
    }
   
   .ts{
       height: 120px !important;
   }
   .ts1{
       margin-top: 33px;
   }
   .line{
    width: 100%;
    height: 5px;
    background: #231DFF;
    margin-top: 43px;
   }
   .kc{
       width: 100%;
       height: 140px;
       display: flex;
       justify-content: center;
       align-items: center;
   }
   .kc span{
    height: 67px;
    font-size: 48px;
    font-family: PingFangSC-Semibold, PingFang SC,PingFang-SC-Regular;
    font-weight: 600;
    color: #000000;
    line-height: 67px;
   }
   .kc_one{
       width: 100%;
       height: 33px;
       display: flex;
       justify-content: center;
       align-items: center;
   }
   .kc_one span{
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC ,PingFang-SC-Regular;
    font-weight: 400;
    color: #000000;
    line-height: 33px;
    opacity: 0.6;
   }
   .kc_main{
       height:634px ;
       margin-top: 83px;
       display: flex;
       justify-content: center;

   }
   .down_one{
    width: 20px;
    height: 80px;
    background: #F9C301;
    position: absolute;
    left: 44%;
    top: -5%;
    
   }
   .kc_main_left{
       width: 600px;
       height: 634px;
       display: flex;
       flex-direction: column;
       
   }
   .kc_main_left li{
       width: 600px;
       height: 150px;
       margin-top: 21px;
       
   }
   .li_one{
       margin-top: 41px !important;
   }
   .li_one_word{
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC ,PingFang-SC-Regular;
    font-weight: 600;
    color: #1D1D1F;
    line-height: 33px;
    margin-top: 10px;
   }
   .li_one_word1{
       width: 506px;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC ,PingFang-SC-Regular;
    font-weight: 400;
    color: #1D1D1F;
    line-height: 28px;
    margin-top: 7px;
    opacity: 0.7;
   }
   .last{
       overflow: hidden;
   }
   .word_ts{
       margin-top: 5px !important;
   }
   .kc_main_right{
       
       width: 540px;
       height: 422px;
       background: url('~@/assets/img_index/images/kc_bj.png') no-repeat  center center;
       display: flex;
       align-items: center;
       background-size: cover;
       position: relative;
    margin-top: 79px;

   }
   .mask{
       position: absolute;
       width: 541px;
       height: 255px;
       background: #E4E7EB;
       opacity: 0.5;
       z-index: -1;
  
    top: 130px;
   }
   footer{
       width: 100%;
       height: 100%;
       background:#000000;
       
   }
   .footer_img{
       width: 100%;
       height: 760px;
       background: url('~@/assets/img_index/images/rzzx.png');
       background-size: 100% 100%;
   }
.footer_word_one{
    width: 100%;
    height: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer_word_one span{
    
height: 67px;
font-size: 48px;
font-family: PingFangSC-Semibold, PingFang SC,PingFang-SC-Regular;
font-weight: 600;
color: #FFFFFF;
line-height: 67px;
}
.footer_word_one1{
    width: 100%;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer_word_one1 span{
    width: 480px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC,PingFang-SC-Regular;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 33px;
}
.lb{
    width: 1200px;
    height: 300px;
    margin: 130px auto;
    display: flex;
}
.lb li{
    width: 270px;
    height: 300px;
    margin-left: 40px;
}
.lb_first{
    margin-left: 0px !important;
}
.lb_img{
    width: 270px;
    height: 187px;
    display: flex;
    justify-content: center;
    
}
.lb_img img{
   width: 140px;
   height: 140px;
   margin-top: 47px;
}
.lb_word{
    height: 113px;
    width: 270px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.lb_word span{
    width: 216px;
height: 33px;
font-size: 24px;
font-family: PingFangSC-Medium, PingFang SC,PingFang-SC-Regular;
font-weight: 500;
color: #FFFFFF;
line-height: 33px;
margin-top: 36px;
}
.bottom{
    width: 100%;
height: 100px;
background: #000000;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
}
.bottom span{
width: 1300px;
height: 25px;
font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC,PingFang-SC-Regular;
font-weight: 400;
color: #FFFFFF;
opacity: 0.7;
line-height: 25px;

}

a{
    color: #FFFFFF;

   text-decoration: none;
}
</style>